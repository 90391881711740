import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col, FormGroup, Label, Input, Alert,
  InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';

import api from '../../api';
import apifunctions from '../../api/functions.js';

import InputPassword from '../PasswordScheme/PasswordInput';

const that = {
  validaTimeout: null,
}

const ValidarDNI = (nro) => {
  return nro.length >= 7 && nro.length < 9;
}

const ValidarCUIT = (cuit) => {
  if (cuit.length != 11) return false;
  let acumulado = 0;
  let digitos = cuit.split("");
  let digito = digitos.pop();
  for (let i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }
  let verif = 11 - (acumulado % 11);
  if (verif == 11) {
    verif = 0;
  } else if (verif == 10) {
    verif = 9;
  }
  return digito == verif;
}

const RegisterModal = (props) => {
  const { showModal, toggle, openLoginModal } = props;
  const [warningMessage, setWarningMessage] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [state, setState] = useState({
    Nombre: "",
    Email: "",
    Telefono: "",
    Domicilio: "",
    CodPostal: "",
    NroDoc: "",
    TipoDocumento_id: "1",
    SituacionIVA_id: "3",
    Localidad_id: "1",
  });

  const [validationState, updateValidationState] = useState({
    showAlertSending: false,
    error: false,
    errorMsg: "",
    validaMail: false,
    disabled: true,
  });

  const [Localidades, SetLocalidades] = useState([]);
  const [TiposDocumentos, SetTiposDocumentos] = useState([]);
  const [SituacionesIVA, SetSituacionesIVA] = useState([]);

  const validate = (type, value, desiredLength) => {
    let ValidateEmail = (mail) => {
      let result = false;
      if (mail.indexOf("@") == -1 || mail.indexOf(".") == -1) {
        result = false;
      } else {
        const timeoutFn = () => {
          clearTimeout(that.validaTimeout);
          updateValidationState({ ...validationState, validaMail: mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) != null });
        }
        clearTimeout(that.validaTimeout);
        that.validaTimeout = setInterval(timeoutFn, 1000);
        result = true;
      }
      return result;
    };
    let ValidateChar = (text, desiredLength) => {
      return (text == undefined || text == null || text.length < desiredLength) == false;
    }
    let result = false;
    switch (type) {
      case 'text':
        result = ValidateChar(value, desiredLength);
        break;
      case 'email':
        result = ValidateEmail(value);
        break;
      default:
        break;
    }
    return result;
  }

  const isFormValid = () => {

    const isNombreValid = validate("text", state.Nombre, 10);
    const isEmailValid = validate("email", state.Email, 10);
    const isDomicilioValid = validate("text", state.Domicilio, 8);
    const isTipoDocValid = state.TipoDocumento_id != "";
    const isSituacionIVAValid = state.SituacionIVA_id != "";
    const isLocalidadValid = state.Localidad_id != "";
    const isCodPostalValid = validate("text", state.CodPostal, 4);
    const isPasswordValid = validate("text", state.NuevaPassword, 8);
    const isNroDocValid = state.TipoDocumento_id == "3" ? ValidarCUIT(state.NroDoc) : ValidarDNI(state.NroDoc);

    const result = isNombreValid && isEmailValid && isDomicilioValid && isCodPostalValid &&
      isNroDocValid && isTipoDocValid && isSituacionIVAValid && isLocalidadValid &&
      isCodPostalValid && isPasswordValid;

    return result;
  }

  const send = () => {
    const successCallback = () => openLoginModal();
    const failureCallback = (response) => updateValidationState({
      ...validationState,
      showAlertSending: false,
      error: true,
      errorMsg: response && response.errors && response.errors.length == 1 ? response.errors[0] : "Se produjo un error en el servidor, reitente nuevamente en unos momentos.",
    });

    updateValidationState({ ...validationState, showAlertSending: true });
    apifunctions.post(api.api.clientes.cliente.alta, null, state, successCallback, failureCallback, failureCallback);
  }

  useEffect(() => {
    const Localidades_SuccessCallback = (response) => {
      if (response.data) SetLocalidades(response.data);
    };
    const Localidades_FailureCallback = () => SetLocalidades([]);

    const SituacionIVA_SuccessCallback = (response) => {
      if (response.data) SetSituacionesIVA(response.data);
    };
    const SituacionIVA_FailureCallback = () => SetSituacionesIVA([]);

    const TiposDocumento_SuccessCallback = (response) => {
      if (response.data) SetTiposDocumentos(response.data);
    };
    const TiposDocumento_FailureCallback = () => SetTiposDocumentos([]);

    const params = { filters: ["paginationEnabled=False"] }

    if (props.showModal) {
      apifunctions.get(api.api.clientes.cliente.localidades, null, null, params, Localidades_SuccessCallback, Localidades_FailureCallback, Localidades_FailureCallback);
      apifunctions.get(api.api.clientes.cliente.situacionesIVA, null, null, params, SituacionIVA_SuccessCallback, SituacionIVA_FailureCallback, SituacionIVA_FailureCallback);
      apifunctions.get(api.api.clientes.cliente.tiposdocumentos, null, null, params, TiposDocumento_SuccessCallback, TiposDocumento_FailureCallback, TiposDocumento_FailureCallback);
    }

    setState({
      Nombre: "",
      Email: "",
      Telefono: "",
      Domicilio: "",
      CodPostal: "",
      NroDoc: "",
      NuevaPassword: "",
      TipoDocumento_id: "1",
      SituacionIVA_id: "3",
      Localidad_id: "0001",
    });
    updateValidationState({
      showAlertSending: false,
      error: false,
      validaMail: false,
      disabled: true,
      errorMsg: "",
    });
  }, [props.showModal]);

  return (
    <Modal centered isOpen={showModal} toggle={toggle} className="register" size="lg">
      <ModalHeader toggle={toggle}>Registro</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="Nombre">Nombre / Razón Social</Label>
          {/* <Input name="Nombre" type="text"
            className={validate("text", state.Nombre, 10) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
            value={state.Nombre}
            onChange={e => setState({ ...state, Nombre: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
          /> */}
          <Input name="Nombre" type="text"
            className={validate("text", state.Nombre, 10) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
            value={state.Nombre}
            onChange={e => {
              const value = e.target.value;
              const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailPattern.test(value)) {
                setState({ ...state, Nombre: value != undefined && value != null && value.length > 0 ? value : "" });
                setWarningMessage(""); // Clear warning message
              } else {
                setWarningMessage("Por favor, ingrese un nombre y no un correo electrónico.");
              }
            }}
          />
          {warningMessage && <p className="text-danger mb-0 text-center">{warningMessage}</p>}

          <Label className="mt-2" for="Email">Email</Label>
          <Input type="email" name="Email" id="Email" placeholder="Ej: abc@gmail.com"
            className={validate("email", state.Email, 10) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
            value={state.Email}
            onChange={e => setState({ ...state, Email: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
          />
          <Row>
            <Col xs={12} md={4}>
              <Label className="mt-2" for="SituacionIVA_id">Condición de IVA</Label>
              <Input type="select" name="SituacionIVA_id" id="SituacionIVA_id"
                value={state.SituacionIVA_id}
                onChange={e => setState({ ...state, SituacionIVA_id: e.target.value, TipoDocumento_id: (e.target.value == "1" ? "3" : state.TipoDocumento_id) })}
              >
                {
                  SituacionesIVA.map((td, index) => (
                    <option key={`SituacionIVA-${index}`} value={`${td.Codigo}`}>{td.Nombre}</option>
                  ))
                }
              </Input>
            </Col>
            <Col>
              <Label className="mt-2" for="TipoDocumento_id">Tipo de Documento</Label>
              <Input type="select" name="TipoDocumento_id" id="TipoDocumento_id"
                value={state.TipoDocumento_id}
                onChange={e => setState({ ...state, TipoDocumento_id: e.target.value })}
                disabled={state.SituacionIVA_id == "1"}
              >
                {
                  TiposDocumentos.map((td, index) => (
                    <option key={`TipoDoc-${index}`} value={`${td.Codigo}`}>{td.Nombre}</option>
                  ))
                }
              </Input>
            </Col>
            <Col className="mt-2" className="mt-auto">
              <Input type="number" min={"0"} max={"99999999999"} name="NroDoc" id="NroDoc" placeholder="Ej: 00000000000"
                value={state.NroDoc}
                className={(state.TipoDocumento_id == "3" ? ValidarCUIT(state.NroDoc) : ValidarDNI(state.NroDoc)) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
                onChange={e => setState({ ...state, NroDoc: e.target.value })}
              />
            </Col>
          </Row>
          <Label className="mt-2" for="Localidad_id">Localidad</Label>
          <Input type="select" name="Localidad_id" id="Localidad_id"
            value={`${state.Localidad_id}`}
            onChange={e => setState({ ...state, Localidad_id: e.target.value })}
          >
            {
              Localidades.map((td, index) => (
                <option key={`Localidad-${index}`} value={`${td.Codigo}`}>{td.Nombre} - {td.Provincia}</option>
              ))
            }
          </Input>
          <Label className="mt-2" for="Domicilio">Domicilio</Label>
          <Input name="Domicilio" id="Domicilio" type="text"
            className={validate("text", state.Domicilio, 8) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
            value={state.Domicilio}
            onChange={e => setState({ ...state, Domicilio: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
          />
          <Row>
            <Col sm={3}>
              <Label className="mt-2" for="CodPostal">Cod. Postal</Label>
              <Input name="CodPostal" id="CodPostal" type="text" placeholder="Ej: 8000"
                className={validate("text", state.CodPostal, 4) ? "rounded-lg border border-success" : "rounded-lg border border-danger"}
                value={state.CodPostal}
                maxLength="4"
                onChange={e => setState({ ...state, CodPostal: e.target.value != undefined && e.target.value != null && e.target.value.length > 0 ? e.target.value : "" })}
              />
            </Col>
            <Col sm={9}>
              <Label className="mt-2" for="Telefono">Telefono</Label>
              <Input name="Telefono" id="Telefono" placeholder="Celular ó Telefono Fijo"
                value={state.Telefono}
                onChange={e => setState({ ...state, Telefono: e.target.value })}
              />
            </Col>
          </Row>

          <hr />
          <InputPassword
            label="Contraseña"
            value={state.NuevaPassword}
            onChange={newValue => setState({ ...state, NuevaPassword: newValue != undefined && newValue != null && newValue.length > 0 ? newValue : "" })}
          />

        </FormGroup>
        {
          (validationState.showAlertSending == true || validationState.error == true) &&
          <Alert color={validationState.error == true ? "warning" : "info"} className="my-3 text-center">
            {
              validationState.showAlertSending == true &&
              <><i className="fa fa-spinner fa-spin mr-2"></i> Procesando</>
            }
            {
              validationState.error == true && validationState.errorMsg
            }
          </Alert>
        }

      </ModalBody>
      <ModalFooter>
        <Button
          disabled={(isFormValid() == false) || (validationState.showAlertSending == true)} color="success" onClick={send}
        ><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> Confirmar</Button>{' '}
        <Button color="secondary" onClick={openLoginModal}><i className="fa fa-times" aria-hidden="true"></i> Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default RegisterModal;
