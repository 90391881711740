import React, { Component, Fragment } from 'react';
import FadeIn from "../../components/Animations/fadeIn.js";
import PageNavigation from '../../components/Navigation/Pagination';
import ProductosHeader from './Header/ProductosHeader.js';
import ProductosFilter from './Filter/ProductosFilter.js';
import { View } from "../../../TiendaConfig";

export default class ProductosView extends Component {
  render() {
    const {
      filters, productos, breadcrumbs, loading, reloading,
      chosenPage, totalPages, search, onChangeSearch, onChangeFilter,
      onShowMore, OnChangeNavigationBar, showBanner, bannerSrc, totalProductos
    } = this.props;

    const showFilters = this.props.showFilter && filters && filters.length > 0;

    return (
      (loading || reloading) ?
        <div className="text-center">
          <View.LoadingScreen loading={true} />
        </div>
        :
        <>
          {
            (showBanner && bannerSrc) &&
            <div className="row col-12 mt-2 mx-auto">
              <img alt="banner oferta" className="rounded d-block mx-auto imagebanner" src={bannerSrc} />
            </div>
          }
          <div className="esige-productos">
            <Fragment>
              {
                totalProductos == 0 ? (
                  <div className="row px-md-4 px-md-0 pr-md-3">
                    {
                      showFilters &&
                      <div className="d-none d-md-block col-md-3 mt-1">
                        <ProductosFilter
                          onChangeFilter={onChangeFilter}
                          breadcrumbs={breadcrumbs}
                          filters={filters}
                          loading={(loading | reloading ? true : false)}
                          onShowMore={onShowMore}
                        />
                      </div>
                    }
                    <div className={showFilters ? "text-center col-9" : "text-center col-12"}>
                      <h2>No hay productos para mostrar</h2>
                    </div>
                  </div>
                ) : (
                  <>
                    <ProductosHeader
                      loading={loading || reloading}
                      breadcrumbs={breadcrumbs}
                      sort={search.get('sort')}
                      pageSize={search.has('pageSize') ? search.get('pageSize') : 24}
                      onChangeSearch={(value, key) => onChangeSearch(value, key)}
                    />
                    <div className="text-right text-nowrap px-md-4 px-md-0 pr-md-4"><small><strong>{totalProductos}</strong> productos encontrados</small></div>
                    <div className="esige-productos-container px-md-4 px-md-0 pr-md-3">
                      {
                        showFilters &&
                        <div className="d-none d-md-block col-md-3 mt-1">
                          <ProductosFilter
                            onChangeFilter={onChangeFilter}
                            breadcrumbs={breadcrumbs}
                            filters={filters}
                            loading={(loading | reloading ? true : false)}
                            onShowMore={onShowMore}
                          />
                        </div>
                      }
                      {
                        reloading ?
                          <div className="esige-productos-list-loader">
                            <View.LoadingScreen loading={true}><div></div></View.LoadingScreen>
                          </div>
                          :
                          <FadeIn>
                            <div className={`esige-productos-list col-xs-12 col-sm-12 ${showFilters ? "col-md-9 col-lg-9" : "col-md-12 col-lg-12"}`}>
                              <div className="row">
                                {
                                  productos.map(
                                    (producto, index) => (
                                      <div
                                        className={`p-2 p-md-3 col-xs-12 col-sm-6 col-md-6 ${showFilters ? "col-lg-6 col-xl-4 col-xxl-3" : "col-lg-3 col-xl-3 col-xxl-2"}`}
                                        key={`articulo-${producto.code}`}
                                      >
                                        <View.ProductoBox orientation="vertical" articulo={producto} maxQuantity={999} />
                                      </div>
                                    )
                                  )
                                }
                              </div>
                              <PageNavigation
                                cantProd={productos.length}
                                currentPage={chosenPage}
                                totalPages={totalPages}
                                OnChangeNavigationBar={OnChangeNavigationBar}
                              />
                            </div>
                          </FadeIn>
                      }
                    </div>
                  </>
                )
              }
            </Fragment>
          </div>
        </>
    );
  }
}
